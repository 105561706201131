




import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet';
import { Variable, VariableResults } from '@/models/variable';
import store from '@/store';

@Component
export default class FilterMap extends Vue {
  @Prop({ required: true }) queryResult: any | null;

  @Prop({ required: true }) variable!: Variable;

  selection: any[] = [];

  mapContainerId = '';

  map: L.Map | null = null;

  featureGroup: L.FeatureGroup | null = null;

  created(): void {
    this.mapContainerId = `map_container_filter_${this.variable.id}`;

    this.$nextTick(() => {
      this.initMap();
    });
  }

  initMap(): void {
    this.map = L.map(this.mapContainerId);

    this.map.on('moveend', () => {
      console.log('invalidate size');
      this.map!.invalidateSize();
    });

    this.featureGroup = L.featureGroup().addTo(this.map);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);

    this.queryResult.forEach((x: any, i: number) => {
      // supports either geojson string or object
      const geoJsonVal = x[this.variable.filterData.geojsonField];
      const geoJSON = typeof geoJsonVal === 'string' ? JSON.parse(geoJsonVal) : geoJsonVal;

      L.geoJSON(geoJSON, { style: { fillOpacity: 0 } }).addTo(this.featureGroup!).on('click', () => {
        console.log('feature clicked', i);
        const value = this.queryResult[i][this.variable.filterData.valuesField];
        this.selection = this.selection.includes(value) ? this.selection.filter((x) => x !== value) : this.selection.concat([value]);
      });

      this.map!.fitBounds(this.featureGroup!.getBounds());
    });
  }

  @Watch('selection', { deep: true })
  onSelectionChange(selection: any[]): void {
    console.log('selection: ', selection);

    this.featureGroup!.getLayers().forEach((layer, i) => {
      const value = this.queryResult[i][this.variable.filterData.valuesField];
      (layer as L.GeoJSON).setStyle({ fillOpacity: this.selection.includes(value) ? 0.8 : 0 });
    });

    const variable: VariableResults = {
      name: this.variable.name,
      values: this.selection,
    };
    store.dispatch('setOrUpdateVariable', variable);
  }
}
